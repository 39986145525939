body {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top:3px
}
:where(.css-1km3mtt).ant-card .ant-card-body {
  padding: 7px 0;
  border-radius: 0 0 8px 8px;
}

.ant-card .ant-card-body {
  padding: 7px 0;
  border-radius: 0 0 8px 8px;
}
.key-highlight {
  color: #8b8d36
}

.key-disabled {
  color: #CCC
}
