.char {
  border-radius: 4px;
  margin: 3px;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 25px;
  width:98%;
  height: 35px;
  font-size: 40px;
  border: 1px solid #ccc;
  color: #262626;
}

.correct {
  background-color: #addbad
}
.wrong {
  background-color:  #f7b9ac
}
.misplaced {
  background-color: #f1ea82
}